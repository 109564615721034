;
/*window.paceOptions = {
  //ajax: false,
	//eventLag: false,
	//restartOnPushState: false
}*/
function masonerija(){
	$('.articles, .sub-pages-listing, .cc-listing_grid .sve  ').masonry({
		itemSelector: '.hentry, .featured-profile',
		columnWidth: '.grid-sizer',
		gutter: '.gutter-sizer',
		percentPosition: true
	})
	
	$('footer #widget-area').masonry({
		itemSelector: '.fWidget',
		columnWidth: '.grid-sizer',
		gutter: '.gutter-sizer',
		percentPosition: true
	});
	
	$('.gallery').prepend('<div class="gal-sizer" style="width:33%"></div>').masonry({
		itemSelector: '.gallery-item',
		columnWidth: '.gallery-item',
		//gutter: '1%',
		percentPosition: true
	});
	
}

$('#oc-footer .row > *[class^="col-"]').each(function(index, element) {
	$(element).attr('class', 'widget fWidget widget_nav_menu')
		.prependTo('#widget-area', 'footer#colophon');
});

masonerija();
$(window).on('load', function(e){
	masonerija();
});
	
$(function(){
		
	$('.social .search form')
		.find('input.search-form')
		.attr('name', 'search')
		.after('<input type="hidden" name="route" value="product/search">');
		
	$('#cart')
		.closest('header').addClass('mobile-header visible-xs').end()
		.clone()
		.prependTo('.social', '.topbar').wrap('<div class="col-sm-3 pull-right social-cart"></div>');
		console.log('run')
});	//	/$(function()