;
var CONFIG = {
	GENERALPREFIX: "mali_igraci_",
	COOKIE_EXPIRATION_DAYS : 100,
	TRANSLATOR_TIMEOUT_SECONDS : 5000 
};

var meta = document.createElement('meta');
meta.name = "Microsoft";
meta.content = "notranslateclasses translit";
document.getElementsByTagName('head')[0].appendChild(meta);

// create get cookie function;
function getCookie(name) {
    function escape(s) { return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1'); };
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
}


var ats_lang_cookie = getCookie(CONFIG.GENERALPREFIX + 'lang');


document.onreadystatechange = function () {
            if (document.readyState == 'complete') {
               // Microsoft.Translator.Widget.Translate('en', 'es', onProgress, onError, onComplete, onRestoreOriginal, 2000);
            }
        }
        //You can use Microsoft.Translator.Widget.GetLanguagesForTranslate to map the language code with the language name
        function onProgress(value) {
            //document.getElementById('counter').innerHTML = Math.round(value);
        }

        function onError(error) {
            console.log("Translation Error: " + error);
        }

        function onComplete() {
            //document.getElementById('counter').style.color = 'green';
        }
        //fires when the user clicks on the exit box of the floating widget
        function onRestoreOriginal() { 
            console.log("The page was reverted to the original language. This message is not part of the widget.");
        }


jQuery(document).ready(function($) {
	if(ats_lang_cookie && ats_lang_cookie!== null && typeof Microsoft == "object"){
	Microsoft.Translator.Widget.Translate('sr-Cyrl', ats_lang_cookie, onProgress, onError, onComplete, onRestoreOriginal, CONFIG.TRANSLATOR_TIMEOUT_SECONDS*1000);
}
	
	$(document).on( "click", "li.language-switch", function() {
    var lang = $(this).data("language");
    if(lang == 'sr-Cyrl'){
			//document.cookie = CONFIG.GENERALPREFIX + 'lang=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    	document.cookie = CONFIG.GENERALPREFIX + 'lang=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
			Microsoft.Translator.Widget.RestoreOriginal();			
	}
    else{
			document.cookie= CONFIG.GENERALPREFIX + "lang="+ lang +"; path=/; ";
			Microsoft.Translator.Widget.Translate('sr-Cyrl', lang, onProgress, onError, onComplete, onRestoreOriginal, CONFIG.TRANSLATOR_TIMEOUT_SECONDS*1000);
			//console.log(lang);
		}
    	//
  });
	
	function transliterate(word) {
    var A = {};
    var result = '';

    A["Ё"]="YO";A["Й"]="I";A["Ц"]="TS";A["У"]="U";A["К"]="K";A["Е"]="E";A["Н"]="N";A["Г"]="G";A["Ш"]="SH";A["Щ"]="SCH";A["З"]="Z";A["Х"]="H";A["Ъ"]="'";
    A["ё"]="yo";A["й"]="i";A["ц"]="ts";A["у"]="u";A["к"]="k";A["е"]="e";A["н"]="n";A["г"]="g";A["ш"]="sh";A["щ"]="sch";A["з"]="z";A["х"]="h";A["ъ"]="'";
    A["Ф"]="F";A["Ы"]="I";A["В"]="V";A["А"]="A";A["П"]="P";A["Р"]="R";A["О"]="O";A["Л"]="L";A["Д"]="D";A["Ж"]="ZH";A["Э"]="E";
    A["ф"]="f";A["ы"]="i";A["в"]="v";A["а"]="a";A["п"]="p";A["р"]="r";A["о"]="o";A["л"]="l";A["д"]="d";A["ж"]="zh";A["э"]="e";
    A["Я"]="YA";A["Ч"]="CH";A["С"]="S";A["М"]="M";A["И"]="I";A["Т"]="T";A["Ь"]="'";A["Б"]="B";A["Ю"]="YU";
    A["я"]="ya";A["ч"]="ch";A["с"]="s";A["м"]="m";A["и"]="i";A["т"]="t";A["ь"]="'";A["б"]="b";A["ю"]="yu";

    for(var i = 0; i < word.length; i++) {
        var c = word.charAt(i);

        result += A[c] || c;
    }

    return result;
}

//document.writeln(transliterate("Драган"));
	
	
});// JavaScript Document